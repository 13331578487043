import { MutationTree }         from 'vuex';
import { Expectation, State }   from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { AdministrationParams, SidePanelState } from '@/types';

export const mutations: MutationTree<State> = {
  setAllExpectationsTypes(state, payload: Expectation[]): void {
    state.expectationsTypes = payload;
  },

  setTableParams(state, params: AdministrationParams): void {
    state.tableParams = params;
  },

  setCurrentItem(state, payload: Expectation): void {
    state.selectedExpectationType = payload;
  },

  setSidePanelState(state, payload: SidePanelState) {
    state.sidePanelState = payload;
  },

  setExpectationsTypesToReplace(state, payload: Expectation[]) {
    state.expectationsTypesToReplace = payload
  }
};
