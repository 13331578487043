
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import CExpectationsTypesForm
  from '@/views/administration/views/administration-expectations-types/components/expectations-types-form/expectations-types-form.component.vue';
import CAdministrationTemplate
  from '@/views/administration/components/administration-template/administration-template.component.vue';
import CSidePanel                                           from '@/components/common/side-panel/side-panel.component.vue';
import { TableColumn, TableRow }                            from '@/components/common/table/table.types';
import { AdministrationPagination, FormMode }               from '@/views/administration/administration.types';
import { AdministrationParams, SidePanelState, SearchbarSelectItem }             from '@/types';
import { useGlobals }                                       from '@/hooks/use-globals';
import { useGlobalTranslations, useTranslations }           from '@/hooks/use-translations';
import CDeleteConfirmation
  from '@/views/administration/components/delete-confirmation/delete-confirmation.component.vue';
import { Expectation } from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';

export default defineComponent({
  name: 'VAdministrationExpectationsTypes',
  components: { CDeleteConfirmation, CSidePanel, CAdministrationTemplate, CExpectationsTypesForm },
  setup() {
    const { store, i18n } = useGlobals();
    const columns: ComputedRef<TableColumn[]> = computed(() => store.state.views.expectationsTypes.columns);
    const rows: ComputedRef<TableRow[]> = computed(() => store.getters[ 'views/expectationsTypes/expectationsTypesTableData' ]);
    const params: ComputedRef<Partial<AdministrationParams>> = computed(() => store.state.views.expectationsTypes.tableParams);
    const isSidePanelOpen: Ref<boolean> = ref(false);
    const sidePanelTitle: Ref<string> = ref('');
    const formMode: Ref<FormMode> = ref(FormMode.ADD);
    const selectedExpectation: ComputedRef<Expectation> = computed(() => store.state.views.expectationsTypes.selectedExpectationType)
    const sidePanelState: ComputedRef<SidePanelState> = computed(() => store.state.views.expectationsTypes.sidePanelState)
    const expectationsToReplace: ComputedRef<SearchbarSelectItem[]> = computed(() => store.getters['views/expectationsTypes/expectationsToReplace'])

    store.commit('views/expectationsTypes/setTableParams', []);
    store.dispatch('views/expectationsTypes/getAllExpectationsTypes');

    const closeSidePanel = (): void => {
      isSidePanelOpen.value = false;
      store.commit('views/expectationsTypes/setSidePanelState', SidePanelState.EDIT_ENTITY)
      store.commit('views/expectationsTypes/setCurrentItem', null)
    };

    const openAddNewExpectationPanel = (): void => {
      formMode.value = FormMode.ADD;
      sidePanelTitle.value = i18n.t('sidePanel.addNewExpectation');
      isSidePanelOpen.value = true;
    };

    const openEditExpectationPanel = async (uuid: string): Promise<void> => {
      closeSidePanel();
      await store.dispatch('views/expectationsTypes/getExpectationEntity', uuid);
      formMode.value = FormMode.EDIT;
      sidePanelTitle.value = i18n.t('sidePanel.editExpectation');
      isSidePanelOpen.value = true;
    };

    const updateParams = async (updatedParams: Partial<AdministrationParams>): Promise<void> => {
      store.commit('views/expectationsTypes/setTableParams', updatedParams);
      await store.dispatch('views/expectationsTypes/getAllExpectationsTypes');
    };

    const goBackToEditForm = ():void => {
      store.commit('views/expectationsTypes/setSidePanelState', SidePanelState.EDIT_ENTITY)
    }

    const replaceExpectationToDelete = async(replaceToExpectationUuid: string): Promise<void> => {
      await store.dispatch('views/expectationsTypes/replaceExpectation', { expectationUuid: selectedExpectation.value.uuid, replaceToExpectationUuid })
      store.dispatch('views/expectationsTypes/getAllExpectationsTypes')
      closeSidePanel()
    }

    return {
      openAddNewExpectationPanel,
      openEditExpectationPanel,
      useTranslations,
      useGlobalTranslations,
      closeSidePanel,
      updateParams,
      goBackToEditForm,
      replaceExpectationToDelete,
      AdministrationPagination,
      SidePanelState,
      sidePanelState,
      formMode,
      columns,
      rows,
      params,
      isSidePanelOpen,
      sidePanelTitle,
      expectationsToReplace,
    };
  },
});
