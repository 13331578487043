import { Module }    from 'vuex';
import { State }     from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { RootState } from '@/store/store.types';
import { state }     from '@/views/administration/views/administration-expectations-types/store/administration-expectations-types.state';
import { mutations } from '@/views/administration/views/administration-expectations-types/store/administration-expectations-types.mutations';
import { actions }   from '@/views/administration/views/administration-expectations-types/store/administration-expectations-types.actions';
import { getters }   from '@/views/administration/views/administration-expectations-types/store/administration-expectations-types.getters';

const store: Module<State, RootState> = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default store;
