import { GetterTree }         from 'vuex';
import { Expectation, State } from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { RootState }          from '@/store/store.types';
import { SearchbarSelectItem } from '@/types';

export const getters: GetterTree<State, RootState> = {
  expectationsTypesTableData(state) {
    if (state.expectationsTypes) {
      return state.expectationsTypes;
    }
    return [];
  },
  selectedExpectationsType(state): Omit<Expectation,'uuid'> & Partial<Pick<Expectation,'uuid'>> {
    if (state.selectedExpectationType) {
      return state.selectedExpectationType;
    }
    return {
      name: '',
    };
  },
  expectationsToReplace(state): SearchbarSelectItem[] {
    return state.expectationsTypesToReplace.map(expectationType => ({ label: expectationType.name, uuid: expectationType.uuid }))
  }
};
