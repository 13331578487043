import { ActionContext, ActionTree }                                                           from 'vuex';
/*eslint-disable */
import {
  Expectation,
  ExpectationTypePayload,
  State,
}                                                                                              from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
/* eslint-enable */
import { RootState }                                                                           from '@/store/store.types';
import { GLOBAL }                                                                              from '@/helpers/variables';
import { AdministrationParams, DataWithPagination, SidePanelState } from '@/types';
import { createEntity, deleteSingleEntity, getEntitiesToReplace, getSingleEntity, getTableData, replaceEntity, updateSingleEntity } from '@/views/administration/administration.service';
import { Notification }                                                                        from '@/components/notification/notification';
import i18n                                                                                    from '@/i18n';
import { AdministrationEndpoints, AdministrationPagination, DeleteEntityResponse } from '@/views/administration/administration.types';

export const actions: ActionTree<State, RootState> = {
  async getAllExpectationsTypes(
    context: ActionContext<State, RootState>
  ): Promise<void> {
    try {
      const { pageSize, currentPage } = context.rootState.pagination[ AdministrationPagination.EXPECTATIONS_TYPES ];
      const params: Partial<AdministrationParams> = context.state.tableParams;
      const allParams: Partial<AdministrationParams> = {
        ...params,
        page: currentPage,
        size: pageSize,
      };

      const { data }: { data: DataWithPagination<Expectation> } = await getTableData(AdministrationEndpoints.EXPECTATIONS_TYPES, allParams);
      context.commit('setAllExpectationsTypes', data.content);
      context.commit(
        'updatePaginationElements',
        {
          totalElements: data.totalElements,
          name: AdministrationPagination.EXPECTATIONS_TYPES,
        },
        { root: true }
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getExpectationEntity(
    context: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      const { data }: { data: Expectation } = await getSingleEntity(AdministrationEndpoints.EXPECTATIONS_TYPES, uuid);
      context.commit('setCurrentItem', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async deleteExpectationEntity(
    { dispatch }: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      await deleteSingleEntity(AdministrationEndpoints.EXPECTATIONS_TYPES, uuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('expectationsTypes.notifications.delete')
      );
    } catch (e) {
      e.data.errorCode === DeleteEntityResponse.DELETE_RESTRICT
        ? dispatch('getExpectationsToReplace', uuid)
        : Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${e.data}`)
        );
      throw e.data;
    }
  },


  async editExpectationEntity(
    context: ActionContext<State, RootState>,
    { uuid, name }: { uuid: string; name: ExpectationTypePayload }
  ): Promise<void> {
    try {
      await updateSingleEntity(AdministrationEndpoints.EXPECTATIONS_TYPES, uuid, { name });
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('expectationsTypes.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async createNewExpectation(
    context: ActionContext<State, RootState>,
    payload: ExpectationTypePayload
  ): Promise<void> {
    try {
      await createEntity(AdministrationEndpoints.EXPECTATIONS_TYPES, payload);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('expectationsTypes.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async getExpectationsToReplace({ commit }, uuid: string) {
    try {
      const { data } = await getEntitiesToReplace(AdministrationEndpoints.EXPECTATIONS_TYPES, uuid)
      commit('setExpectationsTypesToReplace', data)
      commit('setSidePanelState', SidePanelState.DELETE_ENTITY)
    }
    catch(e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async replaceExpectation(context, { expectationUuid, replaceToExpectationUuid }: { expectationUuid: string, replaceToExpectationUuid: string}) {
    try {
      await replaceEntity(AdministrationEndpoints.EXPECTATIONS_TYPES, expectationUuid, replaceToExpectationUuid)
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('expectationsTypes.notifications.replace')
      );
    }
    catch(e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  }
};
