

import { computed, ComputedRef, defineComponent, reactive } from 'vue';
import useVuelidate, { ValidationArgs }                     from '@vuelidate/core';
import { required }                                         from '@vuelidate/validators';
import CFormInput                                           from '@/components/common/form/form-input/form-input.component.vue';
import { FormMode }                                         from '@/views/administration/administration.types';
import { Expectation, ExpectationsTypesFormRules }          from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { useTranslations }                                  from '@/hooks/use-translations';
import CAdministrationFormButtons                           from '@/views/administration/components/administration-form-buttons/administration-form-buttons.component.vue';
import { useGlobals }                                       from '@/hooks/use-globals';
import { Notification }                                     from '@/components/notification/notification';
import i18n                                                 from '@/i18n';
import { GLOBAL }                                           from '@/helpers/variables';

export default defineComponent({
  name: 'CExpectationsTypesForm',
  components: { CAdministrationFormButtons, CFormInput },
  props: {
    formMode: {
      type: String,
      default: '',
    },
  },
  emits: ['close-panel'],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const rules: ExpectationsTypesFormRules = {
      name: { required },
    };
    const selectedType: ComputedRef<Omit<Expectation, 'uuid'> & Partial<Pick<Expectation, 'uuid'>>> = computed(() => store.getters['views/expectationsTypes/selectedExpectationsType']);
    const formFields: Omit<Expectation, 'uuid'> & Partial<Pick<Expectation, 'uuid'>> = reactive(selectedType.value);
    const v$ = useVuelidate((rules as unknown) as ValidationArgs, formFields as any);

    const submitExpectationsForm = async (closePanel: boolean): Promise<boolean> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        try {
          switch (props.formMode) {
            case FormMode.ADD:
              await store.dispatch('views/expectationsTypes/createNewExpectation', formFields);
              await store.dispatch('views/expectationsTypes/getAllExpectationsTypes');
              break;
            case FormMode.EDIT:
              await store.dispatch('views/expectationsTypes/editExpectationEntity', formFields);
              await store.dispatch('views/expectationsTypes/getAllExpectationsTypes');
              break;
            default:
              break;
          }
          v$.value.$reset();
          formFields.name = '';
          if (closePanel) {
            emit('close-panel');
          }
          return true;
        } catch (e) {
          throw Error(e)
        }
      } else {
        Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
        return false;
      }
    };

    const deleteExpectation = async (): Promise<void> => {
      await store.dispatch('views/expectationsTypes/deleteExpectationEntity', formFields.uuid);
      await store.dispatch('views/expectationsTypes/getAllExpectationsTypes');
      emit('close-panel');
    };

    return {
      submitExpectationsForm,
      useTranslations,
      deleteExpectation,
      formFields,
      v$,
      FormMode
    };
  },
});
