import { columnTypes } from '@/components/common/table/table.types';
import { State } from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { FormMode } from '@/views/administration/administration.types';
import { FilterTypes, SidePanelState } from '@/types';

export const state: State = {
  expectationsTypes: null,
  selectedExpectationType: {
    name: '',
    uuid: ''
  },
  tableParams: {
    searchQuery: '',
    'sortBy[0].direction': 'ASC',
    'sortBy[0].field': 'name'
  },
  columns: [
    { name: 'name', type: columnTypes.TEXT, filter: FilterTypes.SORT },
  ],
  formMode: FormMode.ADD,
  expectationsTypesToReplace: [],
  sidePanelState: SidePanelState.EDIT_ENTITY
};
